<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Terrassenüberdachung / Leiner / Area Classic <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        AREA Classic
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full">
          <video class="w-full" width="700" height="600" controls>
            <source
              src="../assets/img/leiner/area-dach/AREA_bio_Effekt_hd720p_neu.mp4"
              type="video/mp4"
              alt="leiner area-dach video"
            />
          </video>
        </div>
        <div class="col-span-full my-10">
          <h3 class="text-2xl pl-5 py-5 md:pb-3" style="color: #033859">
            Harmonisch. Zeitlos. Schön.
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            AREA classic zeichnet sich durch einen Dachüberstand aus. Das
            klassische Dach mit seiner zeitlosen Formgebung paßt an jedes
            Gebäude. Auf Wunsch kann es um eine Regenrinne mit Fallrohr ergänzt
            werden. Auch hier steht die Ästhetik im Vordergrund. Rinne und
            Fallrohr wurden passgenau zum kubischen Design entwickelt<br /><br />
            AREA classic lässt sich erweitern und kombinieren. Nutzen Sie die
            beweglichen Seitenelemente AREA slide oder die feststehenden
            Glaselemente AREA fix und AREA slim. Die leichtgängigen
            Schiebeelemente der AREA slide ermöglichen die Öffnung Ihres
            Glashauses mit nur einem Handgriff. Die rahmenlosen Elemente bieten
            Ihnen ein Maximum an Transparenz und eröffnen einen unverstellten
            Ausblick in die umgebende Landschaft.<br /><br />
          </p>
        </div>
        <div class="col-span-full">
          <div
            class="overflow-x-scroll flex text-gray-600 mt-1 border shadow-sm gap-1 cursor-pointer w-full"
          >
            <img
              src="../assets/img/leiner/area-dach/area-classic-header1.webp"
              style="min-width: 20rem"
              alt="leiner terrassendach glasfront"
            />
            <img
              src="../assets/img/leiner/area-dach/area-led-slider.webp"
              style="min-width: 20rem"
              alt="leiner terrassendach nacht"
            />
            <img
              src="../assets/img/leiner/area-dach/kaltwintergarten2.webp"
              style="min-width: 20rem"
              alt="leiner area-classic mit beleuchtung"
            />
          </div>
        </div>
        <div class="mt-16 col-span-full">
          <p class="w-full px-5 lg:text-center">
            Das feststehende Glaselement AREA fix bietet Ihnen eine solide
            Festverglasung und schützt somit Ihren Lieblingsplatz vor schräg
            einfallendem Regen und Seitenwind. So schirmen Sie sich zuverlässig
            gegen Zugluft ab und machen Ihren Lieblingsplatz noch ein bisschen
            ­behaglicher. Die solide Festverglasung AREA slim bietet bei einer
            Dachtiefe von bis zu 3 m als fast rahmenloses Glassystem ein Maximum
            an Transparenz und einen nahezu uneingeschränkten Blick ins
            Freie.<br /><br />
            Mit AREA led - der dimmbaren LED-Beleuchtung sorgen Sie für
            stimmungsvolle Atmosphäre und genießen die Abende auf Ihrer
            Terrasse. Notentwässerungen am AREA Terrassenglassystem sorgen für
            den Schutz vor Frostschäden.
          </p>
        </div>
      </div>
      <div class="p-4">
        <h4 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h4>
        <tabs :mode="mode">
          <tab title="Details">
            <p class="text-lg">
              AREA classic ist bis zu einer Dachbreite von 700 cm als
              Einzelelement baubar. Bei größeren Breiten werden die Profile
              gekuppelt. Maximale Dachtiefe bei AREA 100 beträgt 350 cm und bei
              AREA 150 600 cm. Welche Baugrößen realisierbar sind hängt von der
              regionalen Schneelastklasse ab.<br /><br />
            </p>
          </tab>
          <tab title="Sonderausstattung">
            <p class="text-lg">
              <span class="font-bold">Regenrinne</span> <br />
              Konsequent geradlinig fügen sich Dachrinne und Fallrohr passgenau
              in das architektonische Gesamtkonzept ein. Auf Wunsch auch mit
              Regenablaufkette.<br /><br />
              <span class="font-bold">AREA led</span> <br />
              Dimmbare AREA led Elemente verbreiten auf Wunsch atmosphärisches
              Licht. Auch ein nachträglicher Einbau ist jederzeit möglich.<br /><br />
              <span class="font-bold">AREA slide</span> <br />
              AREA slide - rahmenlose, leichtgängige Schiebeelemente ermöglichen
              ein einfaches Öffnen und Schließen Ihres Glashauses.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },
};
</script>
